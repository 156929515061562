//Colors
$falabella-black: #303335;
$falabella-green: #49ad33;
$falabella-green-2: #43b12e;
$falabella-gray: #54585a;
$falabella-gray-2: #525251;
$falabella-red: #ec003d;
$falabella-text: #2c2c2f;
$falabella-orange: #49ad33;
$falabella-celeste: #f8fafb;
$falabella-light: #f4f7f8;
$light-gray: #717478;
$light-gray-2: #999ca0;
$light-gray-3: #f8f9fb;
$light-gray-4: #f0f3f7;
$cool-gray: #c6c7c8;
$warm-grey: #eee9e6;
$warm-gray: $warm-grey; // spelling difference
$warm-gray-2: #fcfaf8;

$light-gray-: $light-gray-2;
$light-gray--: $light-gray-3;
$light-gray---: $light-gray-4;

$focus-blue: #1e77bb;
$error-red: #bb4e1e;

$dot: $cool-gray;
$white: #ffffff;
$inactive: #f4cfb7;
$green: #50842b;
$cool-blue: #e2e8f086;
$color-successful: #43a047;
$bg-successful: #dff0d8;
$color-text-warning: #ffb41f;
$bg-warning: #fff8ea;
$bg-error: #fddcda;
$color-error: #f44336;

$button-funnel-active: $falabella-green;
$button-funnel-inactive: $inactive;
$button-funnel-final: $green;

//Footer
$footer-h4: #999ca0;
$footer-link: #53565a;
$footer-copyright: #999ca0;

//Media Query Sizes
$screen-sm-min: 575px;
$screen-md-min: 991px;
$bp--md: $screen-md-min;
$screen-lg-min: 991px;
$bp--lg: $screen-lg-min;
$screen-xlg-min: 1024px;
$screen-xxlg-min: 1199px;

$bp--desktop-nav: $screen-lg-min;

$header-bar--height--mobile: 56px;

//Font Size
$font-12: 12px;
$font-13: 13px;
$font-14: 0.875rem;
$font-16: 1rem;
$font-18: 1.125rem;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-25: 1.563rem;
$font-26: 26px;
$font-28: 28px;
$font-42: 42px;
$font-48: 48px;
$font-56: 56px;
$font-60: 60px;

// Layout
$margin: 50px;
$margin--mobile: 28px;
$funnel-main-height: calc(100vh - 178px);

$toast-margin-bump--mobile: 56px; // How much margin to add to make space for Toasts ("Tienes una Emergencia?)

// Animation
$fast: 250ms;
$slow: 500ms;
