@import '../../styles/variables.scss';
@import '../../styles/accordian.scss';
@import '../../styles/jump-nav.scss';

.detailJob {
	font-size: 1.6rem;
	margin-bottom: 2rem;
}

.listJob {
	font-size: 1.6rem;
}

.empleos {
	.accordion__heading {
		p {
			font-size: 1.6rem;
			margin: 6px 0;
		}
	}

	.accordion__panel {
		p,
		ul {
			margin-bottom: 30px;

			&:last-child {
				margin: 0;
			}
		}

		ul {
			li:before {
				content: '\2022';
				color: $falabella-green;
				margin-right: 6px;
			}
		}
	}
}

.titleJobs {
	font-family: 'MavenPro-Bold', sans-serif;
	font-size: 16px;
	font-weight: bold;
}
.blackLink {
	color: #717478;
	letter-spacing: -0.05rem;
	font-size: 16px;
}

.accordion__button {
	&::after {
		background: none !important;
	}
}
