@import '../../../styles/variables.scss';

.funnel-header-container {
	width: 100%;
	height: 4px;
	display: flex;
	justify-content: space-between;
	background-color: $falabella-black;
}

.funnel-header-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 20px 30px;

	&-left {
		&-logo {
			width: 141px;
			height: 40.31px;
			margin-left: 20px;
			display: block;
			background-size: (141px 54px);
			background-image: url('../../../images/svg/seguros-falabella-logo.svg');
			background-repeat: no-repeat;
		}
	}
	&-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		a {
			padding: 12px;
		}

		&-close-btn {
			fill: $falabella-gray;
		}
	}
}

.funnel-header-progress {
	height: 100%;
	border-radius: 0 2px 2px 0;
	background-color: $falabella-green-2;
	&-0 {
		background-color: $white;
		width: 100%;
	}
	&-1 {
		width: 25%;
	}
	&-2 {
		width: 50%;
	}
	&-3 {
		width: 75%;
	}
	&-4 {
		width: 100%;
		border-radius: 0;
	}
}

.funnel-header-content-right {
	a {
		img {
			display: block;
		}
	}
}
