@import '../../styles/variables.scss';

.banner {
	&__container {
		@media only screen and (max-width: $screen-md-min) {
			height: max-content;
		}
		.banner__info__message {
			flex-direction: column;
			@media only screen and (min-width: $screen-md-min) {
				flex-direction: row;
			}
		}
		.banner__bono__terms {
			color: #fff;
			margin-left: 0.3rem;
			font-weight: 200;
			text-decoration: underline;
			font-family: 'MavenPro-Regular', sans-serif;
		}
	}
}
.banner__text {
	&__titles {
		margin: 0 auto;
		span {
			color: #e2003e;
		}
	}
	&__dates {
		color: #566976;
		text-align: center;
		font-family: MavenPro-Bold, sans-serif;
		font-size: 10px;
		font-style: normal;
		line-height: 1.0625rem;
		letter-spacing: 0.00625rem;
		margin: 0 auto;
	}
	&__info {
		margin-bottom: 0.88rem;
		margin-top: 0.3rem;
		text-align: center;
	}
}
