@import '../../../styles/variables.scss';
.service-hero-products-container {
	display: flex;
}

.service-hero-products-image {
	height: auto;
	width: 100%;
	object-fit: cover;
	img {
		width: 100%;
		object-fit: cover;
	}
	@media (min-width: $bp--lg) {
		margin-bottom: 50px;
		max-width: 849px;
		max-height: 300px;
	}
}
