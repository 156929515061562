@import '../../styles/variables.scss';

.banner__primary {
	padding-bottom: 42px;
	background-color: $white;
	color: $falabella-black;
	position: relative;
	display: flex;
	.arrow-slide {
		height: 53px;
		width: 30px;
		margin-top: 223px;
		cursor: pointer;
		position: absolute;
		@media only screen and (min-width: $bp--desktop-nav) {
			margin-top: 345px;
		}
		z-index: 2;
		&-left {
			left: 1rem;
			@media only screen and (min-width: $bp--desktop-nav) {
				left: 2rem;
			}
		}
		&-right {
			right: 1rem;
			@media only screen and (min-width: $bp--desktop-nav) {
				right: 2rem;
			}
		}
	}
	.container__swiper {
		width: 100%;
		height: auto;
		.slide-container {
			display: flex;
			flex-direction: column-reverse;
			position: relative;
			@media only screen and (min-width: 600px) {
				flex-direction: row;
			}

			.slide-image {
				height: auto;
				width: 100%;
				max-width: 100%;
				@media only screen and (min-width: 600px) {
					max-width: 50%;
					width: 50%;
				}
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: right;
				}
			}
		}
		.slide-content {
			display: flex;
			margin: 100px auto 0px auto;
			position: relative;
			padding: 0 1rem;

			@media only screen and (min-width: $bp--desktop-nav) {
				margin: 189px 0 100px 0;
			}
			h1 {
				margin-bottom: 0.5em;

				@media only screen and (min-width: $bp--desktop-nav) {
					text-align: left;
				}
			}

			p {
				max-width: 230.06px;
				margin: 28.44px auto 34.99px auto;
				@media only screen and (min-width: $bp--desktop-nav) {
					max-width: 478px;
					text-align: left;
				}
			}

			.orange-button {
				margin-bottom: 1.5em;
			}

			.button {
				width: 200px;
				margin: 0 auto;
				@media only screen and (min-width: $bp--desktop-nav) {
					margin: 0 auto 0 0;
				}
			}
		}

		.slide-link {
			width: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 1.375rem auto 30px auto;
			@media only screen and (min-width: $bp--desktop-nav) {
				margin: 1.375rem auto 0 0;
			}
		}
	}
}
