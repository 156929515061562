.loadingContent {
	z-index: 10000;
	width: 100%;
	height: 127vh;
	background-color: rgba(0, 0, 0, 0.137);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	svg {
		stroke: #49ad33;
	}
}
