@import '../../styles/variables.scss';

.detail__plan {
	&__container {
		display: flex;
		gap: 32px;
		@media only screen and (max-width: 1007px) {
			gap: 10px;
			overflow-x: scroll;
		}
		.detail__plan__container__section {
			min-width: 274px;
			&.small {
				min-width: 160px;
			}
			.detail__plan__header {
				border-radius: 5px 5px 0px 0px;
				margin-bottom: 10px;

				.titles {
					border-radius: 5px 5px 0px 0px;
					background-color: #54565a;
					color: #fff;
					padding: 20px;
					font-weight: 100;
					font-size: 12px;
					min-height: 100px;
					@media only screen and (max-width: 1007px) {
						min-height: 130px;
					}
					&--primary {
						font-size: 20px;
						line-height: 30px;
						font-weight: bold;
					}
				}
				.section {
					display: flex;
					background-color: #ecf8e8;
					padding: 20px;
					min-height: 60px;
					justify-content: space-between;

					&.section--columns {
						padding: 0 20px;
						.column {
							width: 40%;
							display: flex;
							justify-content: flex-start;
							align-items: center;
						}
					}
				}
			}
			.detail__plan__body {
				height: 80px;
				padding: 0 20px;
				background: rgba(198, 199, 200, 0.1);
				margin: 5px 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				small {
					font-size: 12px;
				}
				&.body--row {
					flex-direction: row;
					align-items: center;

					.column {
						width: 40%;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						flex-direction: column;
					}
				}
			}
			.divider {
				height: 100%;
				width: 20%;
				display: flex;
				justify-content: center;
				padding: 5px 0;
				div {
					width: 1px;
					height: 100%;
					background-color: #c6c7c8;
				}
			}
		}
	}
}
