@import '../../../styles/variables.scss';

.productos-buy {
	&-container {
		width: calc(100% - 60px);
		max-width: 460px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 80px;
		z-index: 4;
		text-align: center;

		@media (min-width: $bp--lg) {
			float: right;
			right: calc(50% - 600px);
			margin-right: 20px;
			top: 70px;
			margin-top: -260px;
			margin-bottom: 40px;
			z-index: 12;
		}
		&-header {
			display: flex;
			flex-direction: column;
			align-items: center;
			img {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				@media (min-width: $bp--lg) {
					width: 64px;
					height: 64px;
				}
			}
			&-h3 {
				font-size: 25px;
				line-height: 35px;
				margin-bottom: 20px;
				color: #324552;

				@media (min-width: $bp--lg) {
					font-size: 30px;
					line-height: 40px;
					margin-bottom: 10px;
				}
			}
		}
	}
	&-content {
		background-color: white;
		box-shadow: 0 8px 32px 0 rgba(21, 13, 0, 0.1);
		margin: 25.56px 0 0 0;

		@media (min-width: $bp--lg) {
			margin: 25.56px 30px 0 30px;
		}
		&-padding {
			padding: 17px 30px;
			@media only screen and (min-width: $screen-md-min) {
				padding: 20px 40px;
			}
		}
		&-h3 {
			text-align: center;
			color: $falabella-gray;
			align-items: center;
			white-space: nowrap;
			font-size: $font-18;
			@media only screen and (max-width: $screen-md-min) {
				margin-left: auto;
				margin-right: auto;
			}
		}
		&-info {
			text-align: center;
			max-width: 169.63px;
			margin: 10px auto 0 auto;
			line-height: 17px;
			font-size: $font-14;
		}

		&-price {
			text-align: center;
			margin-bottom: 20px;
			& span {
				font-size: $font-25;
				font-family: 'MavenPro-Bold';
			}
		}

		&-btn {
			margin-top: 25px;
			width: 100%;
		}
		&-line {
			margin: 20px 0;
		}
		&-divider {
			height: 1px;
			background: #49ad33;
			margin: 20px -30px;
			@media only screen and (min-width: $screen-md-min) {
				margin: 20px 0px;
			}
		}
	}
}
