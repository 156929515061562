@import '../../../styles/variables.scss';

.radio-button {
	padding: 21.08px 15px 16px 15px;
	border-radius: 10px;
	width: 50%;
	display: flex;
	flex-direction: column;

	&:hover {
		cursor: pointer;
	}
}

.radio {
	display: flex;
	align-items: center;

	.radio-indicator {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 1.25rem;
		width: 1.25rem;
		border-radius: 1rem;
		border: 1px solid $cool-gray;
		background-color: $white;
		margin-right: 10px;

		&.active {
			border: 1px solid $falabella-green;
		}
	}

	.radio-label {
		color: $falabella-black;
		font-size: 0.688rem;
		text-transform: uppercase;
		letter-spacing: 0.25px;
	}
}

// Add Orange Dot on Active
.radio-button.active {
	.radio-indicator::after {
		content: '';
		background: $falabella-green;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 0.5rem;
	}
}

.radio-qty {
	font-family: 'MavenPro-Bold', sans-serif;
	font-size: 1.25rem;
	color: $falabella-black;
	margin: 0.5rem 0 0 1.7rem;

	@media only screen and (min-width: $screen-sm-min) {
		font-size: 1.25rem;
	}

	@media only screen and (min-width: $screen-md-min) {
		font-size: 1.25rem;
	}
}

.radio-discount {
	color: $falabella-green;
	text-align: left;
	text-transform: none;
	margin-left: 1.7rem;
	font-size: 11px;
	letter-spacing: 0.25px;
	margin-bottom: 0.5rem;
	&.iva {
		margin-top: auto;
		color: $falabella-black;
	}
}

.active-indicator {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	width: 50%;
	left: 0;
	z-index: -9;
	border: 2px solid $falabella-green;
	background-color: $white;
	transition: left 300ms ease-in-out;
	border-radius: 10px;
}

.radio-button.active .active-indicator {
	left: 50%;
}
