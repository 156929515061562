@font-face {
	font-family: 'MavenPro-Regular';
	src: url('./fonts/Maven/MavenPro-Regular.ttf') format('truetype');
}
@font-face {
	font-family: 'MavenPro-Black';
	src: url('./fonts/Maven/MavenPro-Black.ttf') format('truetype');
}
@font-face {
	font-family: 'MavenPro-Bold';
	src: url('./fonts/Maven/MavenPro-ExtraBold.ttf') format('truetype');
}
@font-face {
	font-family: 'MavenPro-Italic';
	src: url('./fonts/Maven/MavenPro-Regular.ttf') format('truetype');
	font-style: italic;
}
@font-face {
	font-family: 'MavenPro-Medium';
	src: url('./fonts/Maven/MavenPro-Medium.ttf') format('truetype');
}
@font-face {
	font-family: 'MavenPro-SemiBold';
	src: url('./fonts/Maven/MavenPro-SemiBold.ttf') format('truetype');
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-size: 14px;
	color: #313B46;
	font-family: 'MavenPro-Regular', sans-serif;
}
.MuiInputLabel-filled {
	transform: translate(12px, 10px) scale(1) !important;
}