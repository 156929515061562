@import '../styles/variables.scss';

.banner a.mas-info {
	margin-top: 10px;
}

.experiencia-connect {
	max-width: 1200px;
	margin: 100px auto;
	display: flex;
	flex-direction: column;
	&-content {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		@media only screen and (min-width: $bp--desktop-nav) {
			flex-direction: row;
		}
	}
	.container__btns {
		display: flex;
		flex-direction: column;
		justify-content: center;
		@media only screen and (min-width: $screen-md-min) {
			flex-direction: row;
		}
	}
	.button {
		width: 200px;
		margin: 60px auto 0 auto;
		@media only screen and (min-width: $bp--desktop-nav) {
			margin: 0px 1rem 0 0;
		}
	}
}
