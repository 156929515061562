@import '../../../styles/variables.scss';

.input__file {
	&__container {
		width: 100%;
		position: relative;
		height: 55px;
		border: 1px solid #ebf1f5;
		border-radius: 6px;
		margin-top: 10px;
		&.error {
			border: 3px solid #ec003d;
    background-color: rgba(187, 78, 30, 0.1);
		}
		&:hover {
			border-color: $falabella-green;
		}
		&:focus,
		&:focus-within,
		&:active {
			border: 3px solid $falabella-green;
			box-shadow: 0 0 5px rgb(0 0 0 / 30%);
		}
		label {
			cursor: pointer;
			align-items: center;
			display: flex;
			width: 100%;
			height: 100%;
			font-family: 'MavenPro-Regular', sans-serif;
			padding: 0 12px;
			input {
				display: none;
			}
		}
	}
	&__icon {
		position: absolute;
		top: 17px;
		right: 21px;
	}
}
