.banner__terms__and__conditions {
	&__container {
		border-top:2px;
		height: auto;
		flex-direction: row;
		position: fixed;
		bottom: 0;
		background: #f3f5fc;
		display: flex;
		align-items: center;
		padding: 16px 24px 20px;
		z-index: 100;
		width: 100%;
		justify-content: center;
		box-shadow: 0px 0px 5px 0px #888888;
	}

	&__text {
		font-size: 12px;
		line-height: normal;
		color: #333;
		margin-right: 8px;
		letter-spacing: 0.09px;
		a {
			text-decoration: underline;
			font-family: 'MavenPro-Regular', sans-serif;
			color: #333;
		}
	}

	&__button {
		background: #fff;
		border-radius: 24px;
		min-width: 114px;
		height: 26px;
		color: #333;
		font-size: 14px;
		line-height: 1.4;
		font-family: inherit;
		cursor: pointer;
		padding: 0;
		border: 1px solid var(--state-border-default, rgba(27, 48, 82, 0.3));
	}
}
