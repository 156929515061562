@import '../../../styles/variables.scss';

.nc-wrapper {
	display: flex;
	flex-direction: column;
	align-self: center;
}

.nuestros-clientes {
	background: $white;
	margin-top: 0;
	padding: 72px 0 45px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: $falabella-black;

	@media only screen and (min-width: $screen-md-min) {
		padding: 120px 45px;
	}
}
.nc-title {
	margin: 0 auto;
	max-width: 584px;
	min-height: 4.817rem;
	font-size: $font-14;
	line-height: 15px;
	text-align: center;

	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-14;
		line-height: 15px;
	}
}

.nc-orange-title {
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-18;
		letter-spacing: 0.56px;
		line-height: 22px;
		text-align: center;
	}
}

.nc-name {
	font-family: 'MavenPro-Bold';
	display: flex;
	font-size: $font-14;
	letter-spacing: -0.11px;
	line-height: 24px;
	align-items: center;
}

.nc-clients {
	display: flex;
	flex-direction: row;
	align-self: center;
}

.nc-arrows {
	display: flex;
	width: 0.5rem;
	height: 35px;
	align-self: center;

	&--left {
		margin-right: 15px;
		justify-content: flex-end;
	}
	&--right {
		margin-left: 15px;
	}

	@media (min-width: $screen-md-min) {
		&.mobile {
			display: none;
		}
	}
}

$dots: 5;
$dotSize: 0.4;
$dotMargin: 0.5;
$duration: 0.3s;
$timingEase: cubic-bezier(0.51, 0.92, 0.24, 1);
$timingBounce: cubic-bezier(0.51, 0.92, 0.24, 1.15);
.nc-slide {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.nc-slide-container__slides {
	position: relative;
	width: $dots * 100%;
	height: 100%;
	transition: transform $duration $timingEase;
	will-change: transform;
}

.nc-slide-container__slide {
	float: left;
	width: calc(100% / $dots);
	height: 100%;
}

.nc-slide-container__dots {
	display: flex;
	position: relative;
}

.nc-slide-container__dot {
	display: block;
	margin: 0 #{$dotMargin}rem;
	width: #{$dotSize}rem;
	height: #{$dotSize}rem;
	background: $dot;
	border-radius: 100px;
}

.nc-slide-container__indicator {
	@extend .nc-slide-container__dot;

	position: absolute;
	background: $falabella-orange;
	width: auto;

	&--left {
		transition: left $duration $timingBounce, right $duration calc($duration/3) $timingBounce;
	}

	&--right {
		transition: left $duration calc($duration/3) $timingBounce, right $duration $timingBounce;
	}
}

//New
.nc-slide-container {
	display: flex;
	width: 100%;
	flex-direction: column;
	@for $i from 0 to $dots {
		$slide: calc(100% / $dots);
		$left: $slide * $i;

		&[data-pos='#{ $i }'] &__slides {
			transform: translateX(-$left);
		}
	}

	@for $i from 0 to $dots {
		$dot: $dotSize + $dotMargin * 2;
		$left: $dot * $i;
		$right: $dot * ($dots - $i) - $dot;

		&[data-pos='#{ $i }'] &__indicator {
			left: #{$left}rem;
			right: #{$right}rem;
		}
	}
}

.nc-slide-container-top {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 1 27%;
	@media only screen and (max-width: $screen-md-min) {
		align-items: flex-start;
	}
}

.nc-slide-container-orange-title {
	font-size: 32px;
	line-height: 42px;
	letter-spacing: -0.6px;
	text-transform: uppercase;

	h1 {
		margin-bottom: 20px;
	}
}

.nc-slide-container-center {
	display: flex;
	flex: 1 1 100%;
}

.nc-slide-container-center-left {
	display: flex;
	padding-left: 101px;
	@media only screen and (max-width: $screen-md-min) {
		padding-left: 0px;
	}
}
.nc-slide-container-center-center {
	display: flex;
	align-items: center;
	max-width: 278.8px;
	margin: 0 auto;
	@media only screen and (min-width: $screen-md-min) {
		max-width: 584px;
	}
}
.nc-slide-container-center-right {
	display: flex;
	justify-content: flex-end;
	padding-right: 101px;
	@media only screen and (max-width: $screen-md-min) {
		padding-right: 0px;
	}
}

.nc-slide-container-bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 101px;

	@media only screen and (max-width: $screen-md-min) {
		align-items: flex-end;
	}
}

.nc-slide-container-bottom-left {
	display: flex;
}
.nc-slide-container-bottom-center {
	display: flex;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: $screen-md-min) {
		height: 35px;
	}
}
.nc-slide-container-bottom-right {
	display: flex;
	justify-content: flex-end;
}
