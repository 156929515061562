@import '../../styles/variables.scss';

.footer__inner {
	padding: 56px 35px 40px 35px;
	background-color: $falabella-black;

	@media only screen and (min-width: $screen-md-min) {
		padding: 100px 120px;
	}
}

.nav__cols {
	display: grid;
	gap: 40px;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	.hidden {
		visibility: hidden;
	}
}

.ft-logo-icon {
	width: 20px;
}

.ft-logo {
	width: 141px;
	height: 54px;
	background-size: (141px 54px);
	background-image: url('../../images/svg/grey-logo.svg');
	background-repeat: no-repeat;
	display: block;

	@media only screen and (max-width: $screen-md-min) {
		margin-top: 40px;
	}
}

.ft-copyright {
	line-height: 1.25rem;
	color: $white;
	font-size: 0.625rem;
	margin-top: 4.906px;
}

.logos__dark__container {
	align-items: center;
	display: flex;
	margin-top: 33.41px;
	flex-direction: column;
	img {
		height: 39px;
		width: auto;
		margin-top: 1rem;

		&.logo__connect {
			height: auto;
			width: 180px;
		}
	}
}

footer nav {
	h5 {
		margin-bottom: 0.454rem;
	}

	li {
		line-height: 20px;
		letter-spacing: 0.5px;
	}

	a {
		color: $white;
		font-size: 0.875rem;
		font-family: 'MavenPro-Bold', sans-serif;
		line-height: 20px;
		letter-spacing: 0.5px;
		&.link--green {
			color: $falabella-green;
		}
		&:hover {
			color: $warm-gray-2;
		}
	}

	ul.social {
		display: flex;

		li {
			a {
				padding: 0rem;
			}
		}
	}
}

.footer__country-select {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}

.ft-dropdown-select {
	min-width: 200px;
	margin-left: 5px;
	position: relative;
	display: block;
	border-bottom: 1px solid $light-gray-2;
	background-color: #fff;
	z-index: 10;

	select {
		border: none;
		outline: none;
		background: transparent;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0;
		margin: 0;
		display: block;
		width: 100%;
		padding: 12px 55px 8px 0px;
		font-size: $font-16;
		color: $falabella-gray;
		font-family: 'MavenPro-Bold', sans-serif;
	}

	&:after {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		line-height: 38px;
		content: url('../../images/svg/chevron-orange.svg');
		text-align: center;
		color: $falabella-green;
		font-size: 24px;
		z-index: -1;
	}
}
