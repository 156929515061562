@import '../../styles/variables.scss';

.banner {
	&__container {
		background-color: #e2003e;
		display: flex;
		position: sticky;
		left: 0;
		right: 0;
		top: 0;
		z-index: 100;
		justify-content: space-around;
		padding: 11px;
		cursor: pointer;
		height: 58px;
		.banner__btn__close {
			position: absolute;
			top: 0;
			right: 0;
			background-color: transparent;
		}
	}

	&__content {
		display: flex;
		padding-right: 2rem;
	}

	&__info {
		display: flex;
		align-items: center;
		color: $white;
		&__message {
			display: flex;
			font-weight: 800;
			text-align: center;
			span {
				background-color: #26364580;
				padding: 2px 4px;
			}
			small {
				font-size: 10px;
			}
		}
		&__btn__buy {
			background-color: $white;
			font-size: 12px;
			color: $falabella-black;
			border-radius: 10px;
			padding: 2px 11.5px;
			line-height: 20px;
			height: 24px;
			margin-left: 1rem;
			display: flex;
			@media only screen and (min-width: $screen-md-min) {
				margin-right: 0rem;
				margin-left: 2rem;
			}
			&:hover {
				background-color: #7db971;
				color: $white;
			}
		}
	}
}
