@import '../../styles/variables.scss';

.terminos-container {
	display: flex;
	flex-direction: column;
	padding-top: 60px;
	padding-bottom: 60px;
	justify-content: center;

	@media only screen and (max-width: $screen-md-min) {
		flex-direction: column;
	}
}

.info__container {
	p, ul {
		margin-bottom: 1rem;
	}
}
