@import '../../../styles/variables.scss';

.MuiSelect-root {
	font-size: 1.6rem;
}

svg.MuiSvgIcon-root.dropdown {
	margin-right: 16px;
	border-radius: 5px;
	background-color: white;

	path {
		fill: $falabella-green;
	}
}
