@import '../../../styles/variables.scss';
$animate-duration: 1s;

.funnel-resumen-content {
	position: relative;
	text-align: center;
	@media only screen and (max-width: $screen-md-min) {
		width: 100%;
	}
	&.dark {
		background-color: #f4f7f8;
		border-radius: 17px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}
	&-detail {
		margin: 0 auto;
		&.scroll {
			overflow-x: scroll;
		}
	}
}
.funnel-resumen-notes {
	margin-top: 0.5rem;
	text-align: left;
}

.funel-resumen-link-detail {
	color: #303335;
	font-size: 11px;
	line-height: 20px;
	letter-spacing: 0.25px;
	text-decoration-line: underline;
	font-weight: 400;
	margin: 25px auto 0px auto;
	font-family: 'MavenPro-Regular';
	display: flex;
	max-width: 225.18px;
	padding-bottom: 30px;
	&:hover {
		color: #303335af;
	}
}

.funnel-resumen-top-div {
	color: #303335;
	border-bottom: #49ad3354 solid 1px;
	padding: 20px 0 15px 0;
	text-align: center;
	min-width: 250px;
	margin: 0 1rem 15px 1rem;
	@media only screen and (min-width: $screen-md-min) {
		margin: 0 90px 15px 90px;
	}
}

.funnel-resumen-h4 {
	font-size: $font-14;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.25px;
	font-family: 'MavenPro-Bold';
}

.funnel-resumen-pago {
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	letter-spacing: 0.4px;
	margin-top: 5px;
	font-weight: bold;

	&-subtotal {
		margin-top: 2px;
		font-size: 10px;
		span {
			text-decoration: line-through;
		}
	}
	&-discount {
		margin-top: 2px;
		font-size: 10px;
		color: #e2003e;
	}
}

.funnel-resumen-bottom-div {
	padding: 28px;
	text-align: left;
}

.funnel-resumen-bottom-div ul {
	display: block;
	text-align: left;
	margin-top: 16px;
	text-transform: none;
	list-style-type: none;
}

.funnel-resumen-bottom-div ul li::before {
	content: url('../../../images/svg/checkmark-icon-gray.svg');
	padding: 0 10px 10px 0;
}

.funnel-resumen-bottom-div li {
	display: flex;
	font-size: $font-14;
	letter-spacing: 0;
	line-height: 24px;
	text-decoration: none;
}

.funnel-resume-link {
	margin-top: 10px;
	color: $falabella-gray;
	font-size: $font-16;
	display: block;
	text-decoration: underline;
}

.funnel-resumen-slider-content {
	width: 100%;
	height: 24px;
	display: flex;
	margin-top: 38px;
	margin-bottom: 20px;
	justify-content: center;
}

.funnel-slider-center {
	width: 80%;
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
	background-color: $white;
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
}

.wrap-collabsible {
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
	margin: 15px 12.5px;
}

input[type='checkbox'] {
	display: none;
}

.lbl-toggle {
	display: flex;
	text-align: left;
	margin-top: 0px;
	padding: 15px;
	cursor: pointer;
	transition: all 0.25s ease-out;
	padding-left: 28px;
	justify-content: space-between;

	&.close {
		&::after {
			content: url('../../../images//svg/close-btn.svg');
		}
	}
	&.secondary {
		color: $white;
		background-color: $falabella-green;
		&::after {
			content: url('../../../images//svg/chevron-orange-white.svg');
		}
	}

	&.bold {
		font-family: 'MavenPro-Bold';
	}
}

.lbl-toggle::after {
	content: url('../../../images//svg/chevron-orange.svg');
	display: inline-block;
	padding-left: 10px;
	margin-right: 0.7rem;
	width: 10px;
	height: 10px;
	transition: transform 0.2s ease-out;
}

.collapsible-content {
	max-height: 0px;
	overflow: hidden;
	display: none;
	transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
	max-height: max-content;
	display: block;
}

.toggle:checked + .lbl-toggle {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
	background: $white;
	padding: 0.5rem 1rem;
	@media only screen and (max-width: $screen-md-min) {
		padding: 0;
	}
}

.legend-renovacion {
	font-weight: bold;
	font-size: 12px;
	float: right;
	padding: 10px;
	margin-left: 5px;
	font-style: italic;
	color: $falabella-gray;
}

.white {
	background-color: $white;
	color: $falabella-black;
	box-shadow: 0px 1px 6px rgb(0 0 0 / 12%);
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@mixin fadeIn {
	animation: $animate-duration both fadeIn;
	-webkit-animation: $animate-duration both fadeIn;
}

.fadeIn {
	@include fadeIn;
}
