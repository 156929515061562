.footer__logos__container {
	display: flex;
	flex-direction: column;
    width: 100%;
	.nav__bottom {
		display: flex;
		justify-content: center;
		.logo img {
			margin: 7.25rem auto;
			max-width: 13.625rem;
			width: 223px;
			margin: 173px auto 107px auto;
		}
		&__logos {
			display: flex;
			align-items: flex-start;
			justify-content: flex-end;
			margin-bottom: 20px;
		}
	}
}
