@import '../../../styles/variables.scss';
.info-p {
	margin-bottom: 21.44px;
}
.info__form__container {
	@media only screen and (min-width: $screen-md-min) {
		display: grid;
		column-gap: 10px;
		grid-template-columns: 1fr 1fr;
	}
}
.info-form-input {
	display: block;
	margin-top: 10px;
	width: 100%;
}

.info-grow {
	grid-column: span 2;
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
}

.info-orange-btn {
	margin-top: 60px;
}

.pago-terminos-label {
	margin-top: 14px;
	font-size: 12px;
	a {
		font-family: 'MavenPro-Regular';
	}
}

.info__form__agent {
	margin-top: 10px;
	.info__form__agent__option{
display: flex;
	}
}