@import '../../styles/variables.scss';

.by__connect {
	&__content {
		display: flex;
		flex-direction: column;
		max-width: 646px;
		width: 100%;
		margin: 0 auto;
		.button {
			max-width: 136px;
		}
	}
	&__container {
		padding: 45.35px 36.9px 99.07px 36.9px;
		background-color: $falabella-light;

		@media only screen and (min-width: $bp--desktop-nav) {
			padding: 100px 36.9px 50px 36.9px;
		}
	}
	&__logos {
		align-items: center;
		display: flex;
		margin: 0 0 49px 0;
		justify-content: space-between;
		@media only screen and (min-width: $screen-md-min) {
			justify-content: flex-start;
		}

		.connect {
			width: 85px;
			height: auto;
			position: relative;
			@media only screen and (min-width: $screen-md-min) {
				width: 150px;
				padding-left: 25px;
				border-left: 1px solid #54585a;
			}
		}
		.falabella {
			width: 140px;
			height: auto;
			@media only screen and (min-width: $screen-md-min) {
				width: 163px;
				margin-right: 25px;
			}
		}
	}
	&__titles {
		color: $falabella-gray;
		font-size: 35px;
		font-family: 'MavenPro-Bold';
		margin-bottom: 20px;

		@media only screen and (min-width: $screen-md-min) {
			font-size: 45px;
			line-height: 3.75rem;
		}
		p {
			color: $falabella-gray;
			font-size: 20px;
		}
	}
	&__text {
		color: $falabella-gray;
		max-width: 584px;
		margin: 0 auto 42.59px 0;
	}
}
