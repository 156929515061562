@import '../../../../styles/variables.scss';
.funnel-footer-mobile-secondary {
	background-color: $falabella-green;
}
.funnel-footer-mobile-container {
	height: 48px;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	padding: 0 15px;
}

.funnel-footer-mobile-info-div {
	display: flex;
	flex-grow: 2;

	a {
		padding: 0 15px;

		img {
			display: block;
		}
	}
}

.funnel-footer-mobile-info-div a {
	align-self: center;
	width: auto;
}

.funnel-footer-mobile-ul {
	margin: 0;
	padding: 0;
	justify-content: flex-start;
	display: flex;
	color: rgba(83, 86, 90, 0.4);
	list-style-type: none;
}

.funnel-footer-mobile-ul li {
	color: $falabella-black;
	padding: 12px 10px 10px 0px;
	font-size: 14px;
	letter-spacing: 0.5px;
	line-height: 20px;
	font-family: 'MavenPro-Bold', sans-serif;
	text-transform: lowercase;
	&::first-letter {
		text-transform: uppercase;
	}
	&.funnel-footer-mobile-active {
		color: $falabella-green;
		&.secondary {
			color: $white;
		}
	}
	&.funnel-footer-mobile-disabled {
		color: #8d8e91;
	}
}

.funnel-footer-mobile-ul img {
	height: 10px;
}

.funnel-footer-mobile-tracking {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $falabella-gray;
	font-family: 'MavenPro-Bold', sans-serif;
	font-size: $font-20;
	letter-spacing: 0.63px;
	line-height: 24px;
	flex-grow: 1.3;
	@media only screen and (max-width: $screen-md-min) {
		font-size: $font-16;
	}
}

a.funnel-footer-mobile-close-btn-link {
	display: flex;
	width: auto;
}
